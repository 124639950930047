import React, {Component} from 'react';
import Input from 'muicss/lib/react/input';
import {Grid, Button} from '@material-ui/core';
import Panel from 'muicss/lib/react/panel';
import feathersClient from '../../feathersClient';
import Snackbar from '@material-ui/core/Snackbar';
import {Delete} from '@material-ui/icons'
import {SnackbarContent, IconButton} from '@material-ui/core/';
import CloseIcon from '@material-ui/icons/Close';

const ENTER_KEY = 13;
const COMMA_KEY = 188;
export default class CategoryCreate extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      name:'',
      tags: [],
      value: "",
      notification:{
        openNotification: false,
        messageNotification:'',
        typeNotification:''
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    // this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount(){
      const {id} = this.props.match.params;
      feathersClient.service('categories').get(id).then((a)=>{
          this.setState(a)
      })
  }

  handleChange(e) {
    this.setState({
      value: e.target.value
    });
  }

  handleChangeNameCategory = name => event => {

    this.setState({[name]: event.target.value })

  }

  handleKeyUp(e) {
    const key = e.keyCode;

    if (key === ENTER_KEY || key === COMMA_KEY) {
      this.addTag();
    }
  }

  addTag = async () => {
    const { tags, value } = this.state;
    let tag = value.trim();
    tag = tag.replace(/,/g, "");

    if (!tag) {
      return;
    }

    await this.setState({
      tags: [...tags, { "_id":`${new Date().getTime()}` ,"name":tag}],
      value: ""
    });
    
    feathersClient.service('categories').patch(this.state._id,this.state).then(()=>
    this.setState({notification:{openNotification:true, messageNotification:"Tag agregado", typeNotification:"succes"}})
    ).catch((error)=>{
      this.setState({notification:{openNotification:true, messageNotification:error.message, typeNotification:"FeathersError"}})
    })
    
    setTimeout(
      function() {
          this.setState({openNotification: false});
      }
      .bind(this),
      6000
    );

  }

  saveCategory = async () => {

    const params = {
        tags: this.state.tags,
        name: this.state.name
    }

    feathersClient.service('categories').patch(this.state._id,params).then(()=>
      this.setState({notification:{openNotification:true, messageNotification:"Categoria Editada", typeNotification:"succes"}})
    ).catch((error)=>{
      this.setState({notification:{openNotification:true, messageNotification:error.message, typeNotification:"FeathersError"}})
    })

    setTimeout(
        function() {
            this.setState({openNotification: false});
        }
        .bind(this),
        6000
    );
  }

  dellTag = (i) =>{
    let state = JSON.parse(JSON.stringify(this.state));

    state.tags.splice(i,1);

    this.setState({tags: state.tags});

    feathersClient.service('categories').patch(state._id,state).then(()=>
      this.setState({notification:{openNotification:true, messageNotification:"Tag Eliminado", typeNotification:"succes"}})
    ).catch((error)=>
      this.setState({notification:{openNotification:true, messageNotification:error.message, typeNotification:"FeathersError"}})
    )

    setTimeout(
      function() {
          this.setState({notification:{openNotification:false}});
      }
      .bind(this),
      3000
    );
  }

    render(){
      const { tags, value, name } = this.state;
        return (
          <div>
            <Grid container spacing={16}>
              <Grid item md={7} xs={12}>
                  <Panel>
                    <div>
                      <h3>Modificar Categoria</h3>
                    </div>
                    <div className="form">
                      <div>
                        <Input
                            type="text"
                            placeholder="Nombre de la categoria"
                            value={name}
                            onChange={this.handleChangeNameCategory('name')}
                            className="name-input"
                            required
                          />
                      </div>
                      <div>
                        <Button
                              onClick={this.saveCategory}
                              variant="outlined"
                              color="inherit"
                          >
                          Guardar
                        </Button>
                      </div>
                    </div>
                    </Panel>
                </Grid>
                    <Grid item md={5} xs={12}>
                        <Panel>
                          <div>
                            <div>
                                <h4 className="font-bold">Administrar Tags</h4>
                            </div>
                            <div>
                              <Input
                                  type="text"
                                  placeholder="Escribe el Tag y Presiona ENTER para guardar"
                                  value={value}
                                  onChange={this.handleChange}
                                  ref="tag"
                                  className="tag-input"
                                  onKeyUp={this.handleKeyUp}
                                />
                            </div>
                            <div className="tags">
                                <div className="chips-ps">
                                {
                                    tags.map((tag, i) => {
                                    return (
                                        <div
                                        key={i}
                                        className='chip_green chip-p'
                                        >
                                        {`${tag.name}`}
                                        {<Delete 
                                            onClick={() => this.dellTag(i)}
                                            />}
                                        </div>
                                    )
                                    })
                                }
                                </div>
                                {/* <div>
                                <p>{JSON.stringify(this.state)}</p>
                                </div> */}
                            </div>
                        </div>
                        </Panel>
                    </Grid>
                </Grid>
                <div>
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    open={this.state.notification.openNotification}
                  >
                    <SnackbarContent
                        aria-describedby="client-snackbar"
                        style={this.state.notification.typeNotification == "FeathersError" ? {backgroundColor:'red'}: {backgroundColor: 'black'}}
                        message={
                          <span id="client-snackbar" >
                            {this.state.notification.messageNotification}
                          </span>
                        }
                        action={[
                          <IconButton 
                            key="close" 
                            aria-label="Close" 
                            color="inherit" 
                            onClick={this.handleCloseNotification}>
                            <CloseIcon />  
                          </IconButton>,
                        ]}
                      />
                  </Snackbar>
              </div>
            </div>
        )
    }
};
