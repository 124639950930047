import React, { Component } from 'react';
import { Create, SimpleForm, TextInput, LongTextInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { DateTimeInput } from 'react-admin-date-inputs';
export default class QuotesCreate extends Component{

    render(){
        return (
            <div>
                <Grid container spacing={16}>
                    <Grid item xs={12} md={6}>
                        <Create {...this.props} title={"Crear Cotización"}>
                            <SimpleForm>
                                <TextInput source="process_name" label="Nombre del proceso" />
                                <DateTimeInput source="end_date_process" label="Fecha fin cotización" options={{ format: 'dd/MM/YYYY, HH:mm:ss', ampm: true, clearable: true }} />
                                <LongTextInput source="description" label="Descripción"/>
                            </SimpleForm>
                        </Create>
                    </Grid>
                </Grid >
            </div>
        )
    }
}