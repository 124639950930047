import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import { Person, ContactPhone, ContactMail} from '@material-ui/icons';
import {DialogTitle, DialogContent, DialogContentText, Button, Snackbar, SnackbarContent} from '@material-ui/core';
import feathersClient from '../../../feathersClient';
import Input from 'muicss/lib/react/input';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close'


export default class ModalEditCategory extends Component {

    constructor(props){
        super(props);
        this.state={
            supplier:{
                shareholder_details:{
                    name:'',
                    email:'',
                    phone:''
                }
            },
            notification:{
                typeNotification:'',
                messageNotification:'',
                openNotification:false
            },
            modal_show_shareholder:false
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({modal_show_shareholder:nextProps.modal_show_shareholder,
            supplier:{
                ...nextProps.supplier,
            shareholder_details:{
                ...nextProps.supplier.shareholder_details
            }
        }});
    }

    handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({notification:{
            modal_show_shareholder:false}
        })
      }

    handleChangeShareholderDetails = (name) => event =>{

        this.setState({supplier:{
                ...this.state.supplier,
            shareholder_details:{
                ...this.state.supplier.shareholder_details, [name]: event.target.value
            }
        }});

        console.log(this.state,'----------------')
    }

    SaveShareholderDetails = () =>{
        
        console.log(this.state,'------------------');

        feathersClient.service('suppliers').patch(this.state.supplier._id,this.state.supplier).then(()=>
            this.setState({modal_show_shareholder:false, notification:{typeNotification:'succes', messageNotification:'Accionista modificado', openNotification:true, modal_show_shareholder:false}})
        ).catch((error)=>
            this.setState({modal_show_shareholder:false, notification:{typeNotification:'FeathersError', messageNotification:error.message, openNotification:true}})
        )

        setTimeout(
            function() {
                this.setState({notification:{openNotification:false}});
            }
            .bind(this),
            3000
        );
    }
    
    render(){
        const {supplier} = this.state;
        return(
            <div >
                <Dialog
                    open={this.state.modal_show_shareholder}
                    onClose={this.props.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">{'Información del Accionista'}</DialogTitle>
                    <DialogContent className="text">
                        <DialogContentText id="alert-dialog-description">
                        </DialogContentText>
                            <div>
                                <Input
                                    label="Nombre"
                                    placeholder="Nombre del accinista"
                                    value={supplier.shareholder_details.name}
                                    onChange={this.handleChangeShareholderDetails(`name`)}
                                />
                            </div>
                            <div>
                                <Input
                                    label="Telefono"
                                    placeholder="Telefono"
                                    value={supplier.shareholder_details.phone}
                                    onChange={this.handleChangeShareholderDetails(`phone`)}                                />
                            </div>
                            <div>
                                <Input
                                    label="Correo"
                                    placeholder="Correo"
                                    value={supplier.shareholder_details.email}
                                    onChange={this.handleChangeShareholderDetails(`email`)}
                                />
                            </div>
                            <div>
                                <Button
                                    className={'btn-add-category'}
                                    onClick={() =>this.SaveShareholderDetails()}
                                    variant="outlined"
                                    color="inherit">
                                    Modificar
                                </Button>
                            </div>
                    </DialogContent>
                </Dialog>
                <div>
                    <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.notification.openNotification}
                    >
                    <SnackbarContent
                        aria-describedby="client-snackbar"
                        style={this.state.notification.typeNotification == "FeathersError" ? {backgroundColor:'red'}: {backgroundColor: 'black'}}
                        message={
                            <span id="client-snackbar" >
                            {this.state.notification.messageNotification}
                            </span>
                        }
                        action={[
                            <IconButton 
                            key="close" 
                            aria-label="Close" 
                            color="inherit" 
                            onClick={this.handleCloseNotification}>
                            <CloseIcon />  
                            </IconButton>,
                        ]}
                        />
                    </Snackbar>
                </div>
            </div>
        )
    }
}
