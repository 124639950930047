import React, { Component } from 'react';
import { Admin, Resource } from 'react-admin';
import feathersClient from './feathersClient';
import {restClient, authClient} from 'ra-data-feathers';
import authProvider from './authProvider';
import './App.css';

import sagas from './sagas';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';

import { SupplierShow } from './Components/suppliers/suppliert-show';
import { SupplierList } from './Components/suppliers/supplier-list';
import { SupplierCreate } from './Components/suppliers/suppliert-create'

import CategoryList  from './Components/categories/categoriesList';
import CategoryCreate from './Components/categories/categoryCreate';
import CategoryEdit from './Components/categories/categoryEdit';

import { UserList, UserCreate } from './Components/users'
import { QuotesList, QuotesCreate, QuotesShow } from './Components/quotes';
import dataProviderFactory from './dataProvider';


const restClientOptions = { 
    id: '_id',
    usePatch: true };

const authClientOptions = {
    storageKey: 'feathers-jwt',
    authenticate: { strategy: 'local' },
    permissionsKey: 'permissions',
    permissionsField: 'roles',
    redirectTo: '/login',
};


const i18nProvider = locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
};

class App extends Component {
    state = { dataProvider: null };

    async componentWillMount() {

        const dataProvider = await dataProviderFactory(
            process.env.REACT_APP_DATA_PROVIDER
        );

        this.setState({ dataProvider });
    }

    componentWillUnmount() {
        this.restoreFetch();
    }

    render() {
        const { dataProvider } = this.state;

        if (!dataProvider) {
            return (
                <div className="loader-container">
                    <div className="loader">Loading...</div>
                </div>
            );
        }

        return (
            <Admin
                title=""
                authProvider={authClient(feathersClient, authClientOptions )}
                dataProvider={restClient(feathersClient, restClientOptions)}
                customReducers={{ theme: themeReducer }}
                customSagas={sagas}
                customRoutes={customRoutes}
                dashboard={Dashboard}
                loginPage={Login}
                appLayout={Layout}
                locale="en"
                i18nProvider={i18nProvider}
            >  
                <Resource name="suppliers" list={SupplierList}show={SupplierShow}/>
                <Resource name="categories" list={CategoryList} create={CategoryCreate} edit={CategoryEdit}/>
                <Resource name="quotes" list={QuotesList} create={QuotesCreate} show={QuotesShow}/>
                <Resource name="users" list={UserList} create={UserCreate} />
            </Admin>
        );
    }
}

export default App;
