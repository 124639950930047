import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {DialogTitle, DialogContent, DialogContentText, Button, Snackbar, SnackbarContent } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import feathersClient from '../../../feathersClient';
import Input from 'muicss/lib/react/input';
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close'

export default class ModalCreateSupplier extends Component {

    state={
        supplier:{
            name:'',
            city:'',
            address:'',
            person_type:'Natural',
            document_type: 'C.C',
            document_number: '',
            goverment_id:'',
            from_shareholder:'false',
            shareholder_details:{
                name:'',
                email:'',
                phone:'',
                note:''
            },
            type_sector:'Publico',
            big_taxpayers:false,
            supplier_contacts:[{}],
            supplier_notes:[],
            categories:[],
            categoriesList:[],
        },
        notification:{
            typeNotification:'',
            messageNotification:'',
            openNotification:false
        },
        open_modal_create_supplier: false,
        name:[]
    }

    handleChange = name => event => {
        this.setState({supplier:{...this.state.supplier, [name]: event.target.value }});
    }


    handleCloseNotification = () =>{
        this.setState({notification:{openNotification:false}})
    } 

    handleChangeShareholderDetails = name => event => {
        this.setState({supplier:{
                ...this.state.supplier,
            shareholder_details:{
                ...this.state.supplier.shareholder_details, [name]: event.target.value
            }
        }})

    }

    handleChangeContactData = name => event => {
        const contact_data = JSON.parse(JSON.stringify(this.state.supplier.supplier_contacts));

        !contact_data[0] && contact_data.push({});
        contact_data[0][name] = event.target.value;
        contact_data[0]['main_contact'] = true;

        this.setState({supplier:{
            ...this.state.supplier,
            supplier_contacts : contact_data
        }})
    }

    onCreate = async () => {
        const {shareholder_details} = this.state.supplier;
        // console.log(this.state.supplier.from_shareholder)
        if(this.state.supplier.from_shareholder == 'true'){
            if(!shareholder_details.name || !shareholder_details.email || !shareholder_details.phone){
                this.setState({notification:{openNotification:true, messageNotification:'Debes llenar los datos del accionista', typeNotification:'error'}})
            }
        }
        await feathersClient.service('suppliers').create(this.state.supplier).then(()=>
            this.setState({open_modal_create_supplier: false ,notification:{openNotification:true, messageNotification:'Proveedor creado', typeNotification:'succes'}})
        ).catch((error)=>
            this.setState({open_modal_create_supplier:false ,notification:{openNotification:true, messageNotification:error.message, typeNotification:'error'}})
        )
    }

    componentWillReceiveProps(nextProps){

        this.setState({open_modal_create_supplier: nextProps.open_modal_create_supplier})

    }

    render(){
        const {supplier} = this.state;
        return(
            <div>
               <Dialog
                    open={this.state.open_modal_create_supplier}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                    >
                    <DialogTitle
                        style={{width: 600,  }}
                        id="form-dialog-title"
                        className={'text-center'}
                    >Crear proveedor</DialogTitle>
                    <DialogContent>
                         <div>
                            <Input
                                autoFocus
                                label="Nombre"
                                placeholder="Nombre del proveedor"
                                value={supplier.name}
                                onChange={this.handleChange('name')}
                                fullWidth
                            />
                        </div>
                        <div>
                            <Input
                                label="Ciudad"
                                placeholder="Ciudad del proveedor"
                                value={supplier.city}
                                onChange={this.handleChange('city')}
                            />
                        </div>
                        <div>
                            <Input
                                label="Dirección"
                                placeholder="Dirección"
                                value={supplier.address}
                                onChange={this.handleChange('address')}
                            />
                        </div>
                        <div>
                            <Select 
                                label="Tipo de persona"
                                defaultValue="Natural"
                                onChange={this.handleChange('person_type')}
                            >
                                <Option value="Natural" label="Natural" />
                                <Option value="Juridica" label="Juridica" />
                            </Select>
                        </div>
                        <div>
                            <Select
                                label="Tipo de documento"
                                defaultValue="C.C"
                                onChange={this.handleChange('document_type')}
                            >
                                <Option value="NIT" label="NIT" />
                                <Option value="C.C" label="C.C" />
                                <Option value="C.E" label="C.E" />
                                <Option value="Pasaporte" label="Pasaporte" />
                            </Select>
                        </div>
                        <div>
                            <Input
                                label="Numero de documento"
                                placeholder="Numero de documento"
                                value={supplier.document_number}
                                onChange={this.handleChange('document_number')}
                            />
                        </div>
                        <div>
                            <Select 
                                label="Tipo de sector"
                                onChange={this.handleChange('type_sector')}
                            >
                                <Option value="Publico" label="Publico" />
                                <Option value="Privado" label="Privado" />
                                <Option value="Mixto" label="Mixto" />
                            </Select>
                        </div>
                        <div>
                            <Select 
                                label="Grandes contribuyentes"
                                defaultValue={false}
                                onChange={this.handleChange('big_taxpayers')}
                            >
                                <Option value={false} label="No" />
                                <Option value={true} label="Si" />
                            </Select>
                        </div>
                        {/* <div>
                            <Select 
                                label="Estado"
                                defaultValue=""
                                onChange={this.handleChange('status')}
                            >
                                <Option value="true" label="Activo" />
                                <Option value="false" label="Inactiva" />
                            </Select>
                        </div> */}
                        <div>
                            <Select 
                                label="Pertenece a un accionista?"
                                defaultValue={false}
                                onChange={this.handleChange('from_shareholder')}
                            >
                                <Option value={true} label="Si" />
                                <Option value={false} label="No" />
                            </Select>
                        </div>
                        <div>
                            {this.state.supplier.from_shareholder == 'true' && (
                                <div>
                                    <div>
                                        <Input
                                            label="Nombre"
                                            placeholder="Nombre de accionista"
                                            value={supplier.shareholder_details.name}
                                            onChange={this.handleChangeShareholderDetails('name')}
                                        />
                                    </div>
                                    <div>
                                        <Input
                                            label="Correo"
                                            placeholder="Correo"
                                            value={supplier.shareholder_details.email}
                                            onChange={this.handleChangeShareholderDetails('email')}
                                        />
                                    </div>
                                    <div>
                                        <Input
                                            label="Telefono"
                                            placeholder="phone"
                                            value={supplier.shareholder_details.phone}
                                            onChange={this.handleChangeShareholderDetails('phone')}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            <h3>Datos de contacto</h3>
                        </div>
                        <div>
                            <Select 
                                label="Contacto principal"
                                defaultValue={false}
                                // onChange={this.handleChangeSupplierContacts('main_contact', i)}
                            >
                                <Option value={true} label="Si" />
                                <Option value={false} label="No" />
                        </Select>
                        </div>
                        <div>
                            <Input
                                label="Nombre de contacto"
                                placeholder="Nombre de contacto"
                                onChange={this.handleChangeContactData('contact_name')}
                            />
                        </div>
                        <div>
                            <Input
                                label="Telefono de contacto"
                                placeholder="Telefono de contacto"
                                onChange={this.handleChangeContactData('contact_phone')}
                            />
                        </div>
                        <div>
                            <Input
                                label="Correo de contacto"
                                placeholder="Correo de contacto"
                                onChange={this.handleChangeContactData('contact_email')}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.props.handleClose}
                            color="secondary"
                            variant="outlined"
                        >
                        Cancel
                        </Button>
                        <Button
                            onClick={this.onCreate}
                            variant="outlined"
                            color="inherit"
                        >
                        Guardar
                        </Button>
                    </DialogActions>
                </Dialog>
                <div>
                    <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.notification.openNotification}
                    >
                    <SnackbarContent
                        aria-describedby="client-snackbar"
                        style={this.state.notification.typeNotification == "succes" ? {backgroundColor:'black'}: {backgroundColor: 'red'}}
                        message={
                            <span id="client-snackbar" >
                            {this.state.notification.messageNotification}
                            </span>
                        }
                        action={[
                            <IconButton 
                            key="close" 
                            aria-label="Close" 
                            color="inherit" 
                            onClick={this.handleCloseNotification}>
                            <CloseIcon />  
                            </IconButton>,
                        ]}
                        />
                    </Snackbar>
                </div>
            </div>
        )
    }
}