import React, { Component } from 'react';
import { List, Datagrid, TextField, DateField, DeleteButton, ShowButton } from 'react-admin';

export class SupplierList extends Component{
    render(){
        return(
        <List {...this.props} exporter={false}>
            <Datagrid>
                <TextField source="name" label="Nombre"/>
                <TextField source="approval_status" label="Estado"/>
                <ShowButton label="Mostrar" />
                <DeleteButton label="Eliminar"/>
            </Datagrid>
        </List>
        )
    }
};
