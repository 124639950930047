import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {DialogTitle, DialogContent, DialogContentText, Button, TextField } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import feathersClient from '../../../feathersClient';
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import { Chip } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';


export default class ModalAddCategory extends Component {

    state={
        supplier:{
            name:'',
            city:'',
            address:'',
            person_type:'',
            document_type: '',
            document_number: '',
            goverment_id:'',
            from_shareholder:'',
            shareholder_details:{
                name:'',
                email:'',
                phone:'',
                note:''
            },
            supplier_contacts:[{}],
            supplier_notes:[],
            categories:[],
        },
        categoriesList:[],
        category:{
            tags:[]
        },
        category_now:{
            name:'',
            tags:[]
        },
        category_id:'',
        name:[],
        modal_add_category:false,
        open_snackbar:false
    }

    componentDidMount() { 
        feathersClient.service('categories').find({ query: { $limit: 9999, $sort: { name: 1 } }}).then((categories)=>{
            this.setState({categoriesList:categories.data})
        })
    }

    handleChangeSelectCategory = event => {

        this.setState({category_id: event.target.value});

        feathersClient.service('categories').get(event.target.value).then((category)=> {
            this.setState({category:category})

            this.setState({category_now: {...category, tags:[]}})
        })


    }

    handleCloseSnackbar (){
        this.setState({open_snackbar:false})
    }

    addCategory = async () => {

        let oldCategories = JSON.parse(JSON.stringify(this.state.supplier.categories));
        oldCategories.push(this.state.category_now);

        const params = {
            categories:[
                ...oldCategories
            ]
        }

        await feathersClient.service('suppliers').patch(this.state.supplier._id, params).then((result)=>{
            this.setState({modal_add_category: false, open_snackbar: true, category: { tags: [] }})
            this.props.handleClose()
        });

        setTimeout(
            function() {
                this.setState({open_snackbar: false});
            }
            .bind(this),
            6000
        );

        this.props.componentDidMountShow();
    }


    editTags = async (tag, exists) =>{

        let newCategoryNowTags = [];

        if (exists) {
            newCategoryNowTags = this.state.category_now.tags.filter(it => it._id !== tag._id);
        } else {
            newCategoryNowTags = this.state.category_now.tags;
            newCategoryNowTags.push(tag);
        }

        this.setState({
            category_now: {
                ...this.state.category_now,
                tags: newCategoryNowTags
            }
        });
    }

    componentWillReceiveProps(nextProps){

        this.setState({supplier:this.props.supplier, modal_add_category:nextProps.modal_add_category})

    }


    render(){
        const {supplier, categoriesList} = this.state;
        return(
            <div>
               <Dialog
                    open={this.state.modal_add_category}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                    className='modal-category'
                    >
                    <DialogTitle
                        style={{width: 600 }}
                        id="form-dialog-title"
                        className={'text-center'}
                    >Agregar categoría</DialogTitle>
                    <DialogContent 
                        className='contend-modal-add-category'
                        >
                        <div>
                            <Select 
                                label="Categoría"
                                // defaultValue="Seleccionar"
                                className="select-category-add-category"
                                onChange={this.handleChangeSelectCategory}
                            >
                                <Option value="" label="Seleccionar..." />
                                {
                                    categoriesList.map( category =>
                                        <Option value={category._id} label={category.name} />
                                    )
                                }
                            </Select>
                        </div>
                        <div>
                                {
                                    this.state.category.tags.map((tag, i)=>{
                                        const exists = !!this.state.category_now.tags.filter(it2 => it2._id === tag._id).length;
                                        return (
                                            <Chip 
                                                key={i} 
                                                label={`${tag.name} `} 
                                                variant="outlined" 
                                                className={`chip_card ${exists && 'chip_green'}`} 
                                                onClick={() =>this.editTags(tag, exists)}
                                            />
                                        )
                                    })
                                }
                            </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.props.handleClose}
                            color="secondary"
                            variant="outlined"
                        >
                        Cancel
                        </Button>
                        <Button
                            onClick={this.addCategory}
                            variant="outlined"
                            color="inherit"
                        >
                        Guardar
                        </Button>
                    </DialogActions>
                </Dialog>
                <div>
                <Snackbar
                    open={this.state.open_snackbar}
                    onClose={this.handleClose}
                    TransitionComponent={Fade}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Categoria guardada</span>}
                />
                </div>
            </div>
        )
    }
}