import React, { Component } from 'react';
import {Card, Chip, CardActions, CardContent, Button, Typography, Grid, Snackbar, SnackbarContent, TextField, Tooltip  } from '@material-ui/core';
import feathersClient from '../../feathersClient';
import {Edit, Person, Phone, Mail, Star, Done, DeleteForever, CloudUpload, VerifiedUser} from '@material-ui/icons';
import Input from 'muicss/lib/react/input';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from 'muicss/lib/react/checkbox';
import S3File from './s3-files';
import S3Logo from './s3-logo';
import Dialog from '@material-ui/core/Dialog';
import {DialogTitle, DialogContent, DialogContentText, GridList, GridListTile, GridListTileBar} from '@material-ui/core';
import Option from 'muicss/lib/react/option';
import Select from 'muicss/lib/react/select';
import ModalEditCategory from './Components/modal-edit-category';
import ModalAddCategory from './Components/modal-add-category';
import ModalShowShareholder from './Components/modal-show-shareholder';
import LinearProgress from '@material-ui/core/LinearProgress';

const MySnackbarContentWrapper = (MySnackbarContent);

function MySnackbarContent(props) {
    const { classes, className, message, onClose, variant, ...other } = props;
  
    return (
      <SnackbarContent
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" >
            <Done />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon/>
          </IconButton>,
        ]}
        {...other}
      />
    );
  }


export class SupplierShow extends Component {

    state={
        supplier:{
            notes:[],
            supplier_contacts:[],
            files:[],
            categories:[
                {
                    tags:[]
                }
            ]
        },
        note:'',
        id:null,
        open_notification: false,
        contact_name:'',
        contact_phone:'',
        contact_email:'',
        createdAt:'',
        text:'',
        checked: true,
        checked_legal_representative: false,
        modal_new_contact: false,
        modal_edit_contact: false,
        modal_edit_note:false,
        modal_edit_supplier:false,
        modal_add_category:false,
        modal_category:false,
        modal_show_shareholder:false,
        // modal_change_logo:false,
        linear_progress:false,
        modal_contact:{},
        modal_note:{},
        i:0,
        message:'',
        tags:[],
        category:{}
    }

    componentDidMount = async () => {
        const {id} = this.props.match.params;
        this.setState({id:id})

        await feathersClient.service('suppliers').get(id).then((result)=>{

            const notes = result.notes.sort(function(a,b){
                var d1 = new Date(a.createdAt);
                var d2 = new Date(b.createdAt);
                return (d1== d2) ? 0 : (d1 < d2) ? 1 : -1;
            });
            console.log(notes,'--------------');
            // result.notes.reverse();
            this.setState({supplier: result})
        })
    }

    setParentState = async (url, name) =>{

        await this.setState({supplier:{
            ...this.state.supplier,
            files: [...this.state.supplier.files, {"path":url, "name":name, "createdAt": moment().format()}] 
        }});
        await feathersClient.service('suppliers').patch(this.state.id,this.state.supplier).then(this.setState({
            supplier:{
                ...this.state.supplier
            },
            open_notification:true,
            message:'Archivo cargado'
        }));

    }

    DeleteSupplier = async () =>{
        feathersClient.service('suppliers').remove(this.state.supplier._id).then(()=>{
            this.setState({open_notification:true, message:'Proveedor eliminado'});
            setTimeout(
                function() {
                    window.location.replace(`${window.location.origin}/#/`);
                }
                .bind(this),
                2000
            );
        }).catch((error)=>{
            this.setState({open_notification:true, message:error.message});
        });
    }

    approval_status = async () =>{

        // console.log(this.state.supplier)

        const approval_status = await this.state.supplier.approval_status == 'Pendiente' ? 'Aprobado' : null;

        if(approval_status == 'Aprobado'){
            feathersClient.service('suppliers').patch(this.state.supplier._id, {approval_status:approval_status}).then((result)=>{
                this.setState({open_notification:true, message:'Proveedor aprobado', supplier:result});
            }).catch((error)=>{
                this.setState({open_notification:true, message:error.message});
            });
        }else{
            this.setState({open_notification:true, message:'El proveedor se encuenta aprobado'});
        }

    }

    setParentStateLogo = async (url) => {

        this.setState({linear_progress:true});

        feathersClient.service('suppliers').patch(this.state.supplier._id,{logo:url})
        .then((response)=>{
            this.setState({open_notification:true, message:'Logo guardado', supplier:response, linear_progress:false})
        }
        ).catch((error)=>
            console.log(error)
        )

    }

    openModalEditCategory = (category_id, i) =>{

        this.state.supplier.categories.map((category)=>{
            if(category._id === category_id){

                this.setState({category: category, modal_category: true, i:i, supplier: this.props.supplier,supplier:{
                    ...this.state.supplier
                }});
            }
        })
    }

    handleChangeSupplierContacts = (name, i) => event =>{

        const new_sc = JSON.parse(JSON.stringify(this.state.supplier.supplier_contacts));

        if(name == 'main_contact' || name == 'legal_representative'){
            new_sc[i][name] = event.target.checked;
        }else{
            new_sc[i][name] = event.target.value;
        }

        this.setState({supplier: {...this.state.supplier, supplier_contacts: new_sc}})

    }

    handleChangeNotes = (name, i) => event => {
        const new_notes = JSON.parse(JSON.stringify(this.state.supplier.notes));
        new_notes[i][name] = event.target.value;
        this.setState({supplier:{...this.state.supplier, notes:new_notes, text:event.target.value}});
        // console.log(this.state.supplier,'-----------------------')
    }

    onChange(ev) {
        this.setState({checked: ev.target.checked});
    }

    editContact = async () =>{
        const {match} = this.props;

        // console.log(this.state,'------------');

        feathersClient.service('suppliers').patch(match.params.id,this.state.supplier).then(this.setState({
            supplier:{
                ...this.state.supplier
            },
            open_notification:true,
            message:'Contacto modificado',
            modal_edit_contact: false
        }));

        this.componentDidMount();
    }

    EditSupplier = async () =>{
        const {match} = this.props;

        feathersClient.service('suppliers').patch(match.params.id,this.state.supplier).then(this.setState({
            supplier:{
                ...this.state.supplier
            },
            open_notification:true,
            message:'Proveedor Modificado',
            modal_edit_supplier:false
        }));

        this.componentDidMount();

    }

    editNote = async () =>{
        const {match} = this.props;

        feathersClient.service('suppliers').patch(match.params.id,this.state.supplier).then(this.setState({
            supplier:{
                ...this.state.supplier
            },
            open_notification:true,
            message:'Nota modificada',
            modal_edit_note:false
        }));

        this.componentDidMount();
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    };


    handleChangeSuppier = name => event => {

        this.setState({supplier:{
            ...this.state.supplier,
            [name]: event.target.value
        }})

    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        
        this.setState({ open_notification: false, modal_new_contact: false, modal_edit_contact: false, modal_edit_note: false, modal_edit_supplier:false, modal_category:false, modal_add_category:false, modal_show_shareholder:false /*, modal_change_logo:false*/ });
        // this.componentDidMount();
      };

      openModalEditNote = (values,i) =>{
          this.setState({modal_edit_note: true, modal_note: values , i:i });
      }

      openModalEditSupplier = () =>{
          this.setState({modal_edit_supplier: true });
      }

      handleClickOpen = () => {
        this.setState({ modal_new_contact: true });
        // console.log(this.state,'-------------------');
      };


    addNote = async ()=>{
        if(this.state.note === ''){
            return ;
        }
        await this.setState({supplier:{
            ...this.state.supplier,
            notes: [...this.state.supplier.notes, {"text":this.state.note, "createdAt": moment().format()}] 
        }});
        await feathersClient.service('suppliers').patch(this.state.id,this.state.supplier).then(this.setState({
            supplier:{
                ...this.state.supplier
            },
            note: '',
            open_notification:true,
            message:'Nota agregada'
        }));

        this.componentDidMount();

    }

    onChangeMainContact(ev) {
        this.setState({checked_legal_representative: ev.target.checked});
    }

    openModalEditContact = async (values, i)=>{
        this.setState({ modal_edit_contact: true, modal_contact: values, i:i });
    }

    addContact = async ()=>{

        let main_contact = false;

        if(this.state.checked){
            main_contact = true;
            const contacts = this.state.supplier.supplier_contacts;

            contacts.map((dato)=>{
                if(dato.main_contact == true){
                    dato.main_contact = false
                }
            })
        }else{
            main_contact = false;
        }

        await this.setState({supplier:{
            ...this.state.supplier,
            supplier_contacts: [...this.state.supplier.supplier_contacts,{
                "main_contact": main_contact,
                "contact_name":this.state.contact_name,
                "contact_phone": this.state.contact_phone,
                "contact_email":this.state.contact_email,
                "legal_representative": this.state.checked_legal_representative,
                "createdAt": moment().format()
            }]
        }});

        feathersClient.service('suppliers').update(this.state.id,this.state.supplier).then(this.setState({
            supplier:{
                ...this.state.supplier
            },
            contact_name:'',
            contact_phone:'',
            contact_email:'',
            createdAt:'',
            open_notification:true,
            message:'Contacto agregado'
        }))
    }

    dellContact = (i) => {
        const { id } = this.props.match.params;
        const supplier = JSON.parse(JSON.stringify(this.state.supplier));

        supplier.supplier_contacts.splice(i,1);

        this.setState({supplier:supplier});

        feathersClient.service('suppliers').update(id, supplier).then(this.setState({
            open_notification: true,
            message: 'Contacto eliminado'
        }))
    }

    DellCategory = (i) => {
        let supplier = JSON.parse(JSON.stringify(this.state.supplier));

        supplier.categories.splice(i,1);

        this.setState({supplier:supplier});

        const params = {
            categories:supplier.categories
        }

        feathersClient.service('suppliers').patch(supplier._id,params).then(this.setState({
            open_notification: true,
            message: 'Categoría Eliminada'
        }))

    }

    openModalAddCategory = async () =>{
        this.setState({modal_add_category: true, supplier:this.state.supplier})
    }

    handleChangeShareholderDetails = name => event => {
        this.setState({supplier:{
                ...this.state.supplier,
            shareholder_details:{
                ...this.state.supplier.shareholder_details, [name]: event.target.value
            }
        }})
    }

    showShareholder = async () => {
        this.setState({modal_show_shareholder: true, supplier:this.state.supplier})
    }

    render(){
        const { supplier } = this.state;
        return(
            <div>
                <Grid container spacing={12}>
                    <Grid item xs={12} m={5} md style={{paddingBottom:10, paddingLeft:10, paddingRight:10}}>
                        <div style={this.state.linear_progress ? {} : {display:'none'}}>
                            <LinearProgress />
                        </div>
                        <Card>
                            <CardContent style={{marginBottom: -28}}>
                                <div className="card-supplier-top">
                                    <label 
                                        htmlFor="flat-button-logo"
                                        >
                                        <CloudUpload className="icon-edit-supplier-logo"/>
                                    </label>
                                    <div>
                                        <img 
                                            src={`https://proveedores-puerta-de-oro.s3.us-east-2.amazonaws.com/${supplier.logo}`} 
                                            className="logo-supplier"
                                            style={{objectFit:"contain"}} 
                                            height="90px" width="90px"
                                        />
                                    </div>
                                    <div className="card-supplier-top-text">
                                        <p className="bold-color-main">{supplier.name}</p>
                                        <p className="color-text" style={{marginBottom:0, marginTop:-10}}>{supplier.city}</p>
                                        <p className="color-text">Persona {supplier.person_type}</p>
                                        <p className="color-text">Actividad: {supplier.type_activity}</p>
                                    </div>
                                    <div className="icons-actions">
                                        <Tooltip title={supplier.approval_status == 'Aprobado' ? 'Aprobado' : 'No aprobado'}>
                                            <VerifiedUser
                                                className={supplier.approval_status == 'Aprobado' ? "vefiried_approved" :"vefiried_not_approved"}
                                                onClick={() =>this.approval_status()}
                                            />
                                        </Tooltip>
                                        <Edit 
                                            className="icon"
                                            onClick={() =>this.openModalEditSupplier()}
                                        />
                                        <DeleteForever 
                                            className="icon"
                                            onClick={() =>this.DeleteSupplier()}
                                        />
                                    </div>
                                        <div>
                                            <p 
                                                className={supplier.from_shareholder == true ? "card-supplier-top-star": "card-supplier-top-start-hidden"}
                                                onClick={() =>this.showShareholder()}
                                                >
                                                <Star/> Accionista
                                            </p>
                                        </div>
                                    </div>
                            </CardContent>
                            <CardContent className="cardContent-chips">
                                <div>
                                    <Button
                                        className={'btn-add-category'}
                                        onClick={this.openModalAddCategory}
                                        variant="outlined"
                                        color="inherit">
                                        Agregar categoria
                                    </Button>
                                </div>
                                <div>
                                    {
                                        supplier.categories.map((category, i)=>{
                                            return(
                                                <div>
                                                <p className="chip_card_category"> 
                                                    {category.name} -  
                                                    <span 
                                                        className="EditCategory"
                                                        onClick={() =>this.openModalEditCategory(category._id, i)}
                                                        > {` Asociación `}
                                                    </span>
                                                     -
                                                    <span
                                                        className="DellCategory"
                                                        onClick={() =>this.DellCategory(i)}
                                                        > {`Eliminar`}
                                                    </span>
                                                </p>
                                                    {
                                                        category.tags.map((tag)=>{
                                                            return(
                                                                <Chip label={tag.name} variant="outlined" className="chip_green"/>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </CardContent>
                            <CardActions className={"cardAction"}>
                                <div>
                                    {
                                        supplier.supplier_contacts.map((contact)=>{
                                            return(
                                                contact.main_contact == true && (
                                                    <div>
                                                        <p><b>Contacto principal</b></p>
                                                        <p style={{marginTop: -12}}>{contact.contact_name} - Cel. {contact.contact_phone} - {contact.contact_email}</p>
                                                    </div>
                                                )
                                            )
                                        })
                                    }
                                </div>
                            </CardActions>
                        </Card>
                    </Grid>
                        <Grid item xs={12} md={7} style={{paddingBottom:10, paddingLeft:10, paddingRight:10}}>
                            <div>
                                <CardActions>
                                    <TextField
                                        placeholder="Agregar Nota"
                                        value={this.state.note}
                                        onChange={this.handleChange('note')}
                                    />
                                    <Button
                                        onClick={this.addNote}
                                        variant="outlined"
                                        color="inherit">
                                        Agregar Nota
                                    </Button>
                                </CardActions>
                            </div>
                            <div style={{height: 220, overflowY: 'scroll'}}>
                                {
                                    supplier.notes.map((n, i)=>{
                                        return (
                                            <Grid key={n.id} item xs={12} >
                                                <div className="content-notes">
                                                    <Edit className="iconEditNote" onClick={() =>this.openModalEditNote(n, i)} />
                                                    <p><b>Por: </b>{n.by}</p>
                                                    <p style={{ marginTop:-11, marginBottom:-11}}>{moment(n.createdAt).format('MMMM Do YYYY, h:mm:ss a') }</p>
                                                    <p>{n.text} </p>
                                                </div>
                                            </Grid>
                                        )
                                    })
                                }
                            </div>
                        </Grid>
                    </Grid>
                <div>
                    <h2 style={{marginBottom: '-18px'}}>Contactos</h2>
                    <hr style={{border:1, backgroundColor:'black'}}></hr>
                </div>
                <Grid container spacing={12}>
                    <Grid item xs={12} style={{paddingBottom:10, paddingLeft:10, paddingRight:10}}>
                        <div>
                            <Button
                                onClick={this.handleClickOpen}
                                variant="outlined"
                                color="inherit"
                            >
                                Agregar Contacto
                            </Button>
                        </div>
                    </Grid>
                    {
                        supplier.supplier_contacts.map((contact,i)=>{
                            return (
                                <Grid key={contact.id} item xs={12} md={3} style={{paddingBottom:10, paddingLeft:10, paddingRight:10}} >
                                    <Card className={"cardContact"}>
                                        <div className={'contendIcons'}>
                                            <Edit 
                                                className="iconEditContact"
                                                onClick={() => this.openModalEditContact(contact, i)}
                                            />
                                            <DeleteForever 
                                                className="iconDellContact"
                                                onClick={() => this.dellContact(i)}
                                            />
                                        </div>
                                        <CardContent style={{padding: 0}}>
                                            <Typography noWrap variant="h5" component="h4" className = {'cardContactHeader'} >
                                                <Person /> {contact.contact_name}
                                            </Typography>
                                            <Typography noWrap color="textSecondary">
                                                <Phone /> {contact.contact_phone}
                                                <br />
                                                <Mail /> {contact.contact_email}
                                                <br />
                                                {
                                                    (contact.legal_representative) && (
                                                        "Representante legal"
                                                    )
                                                }
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                <div>
                    <h2 style={{marginBottom: '-18px'}}>Archivos</h2>
                    <hr style={{border:1, backgroundColor:'black'}}></hr>
                </div>
                <Grid container spacing={12}>
                    <Grid item xs={12} style={{paddingBottom:10, paddingLeft:10, paddingRight:10}}>
                        <div>
                            <S3File setParentState = {this.setParentState} />
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{paddingBottom:10, paddingLeft:10, paddingRight:10}}>
                        <div>
                        <GridList cols={7}>
                            {this.state.supplier.files.map(file => (
                            <GridListTile key={file.path} >
                                {
                                    ['jpg','png','jpeg','gif'].includes((file.name).split('.')[1].toLowerCase()) && <img src={`https://proveedores-puerta-de-oro.s3.us-east-2.amazonaws.com/${file.path}`} alt='' /> 
                                }
                                {
                                    (file.name).split('.')[1] == 'pdf' && <img src={`https://proveedores-puerta-de-oro.s3.us-east-2.amazonaws.com/suppliers/9/files/apps.34961.13510798887621962.47b62c4c-a0c6-4e3c-87bb-509317d9c364.png`} alt='' /> 
                                }
                                {
                                    (file.name).split('.')[1] == 'docx' && <img src={`https://proveedores-puerta-de-oro.s3.us-east-2.amazonaws.com/suppliers/9/files/1435749302_447980_1435749358_noticia_normal.jpg`} alt='' /> 
                                }
                                {
                                    ['xls','xlt','xls','xml','xlsx'].includes((file.name).split('.')[1].toLowerCase()) && <img src={`https://proveedores-puerta-de-oro.s3.us-east-2.amazonaws.com/suppliers/9/files/descarga.png`} alt='' /> 
                                }
                                <GridListTileBar
                                    title={file.name}
                                />
                            </GridListTile>
                            ))}
                        </GridList>
                        </div>
                    </Grid>
                </Grid>
                <div>
                    <Dialog
                        open={this.state.modal_new_contact}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Nuevo contacto"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            <div>
                                <Input
                                    label="Nombre del contacto"
                                    placeholder="Nombre del contacto"
                                    value={this.state.contact_name}
                                    onChange={this.handleChange('contact_name')}
                                />
                            </div>
                            <div>
                                <Input
                                    label="Telefono de contacto"
                                    placeholder="Telefono de contacto"
                                    value={this.state.contact_phone}
                                    onChange={this.handleChange('contact_phone')}
                                />
                            </div>
                            <div>
                                <Input
                                    label="Correo"
                                    placeholder="Correo"
                                    value={this.state.contact_email}
                                    onChange={this.handleChange('contact_email')}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    label="Representante legal"
                                    checked={this.state.checked_legal_representative}
                                    onChange={this.onChangeMainContact.bind(this)}
                                />
                            </div>
                            <div>
                                <Checkbox
                                    label="Contacto principal"
                                    checked={this.state.checked}
                                    onChange={this.onChange.bind(this)}
                                />
                            </div>
                            <div>
                                <Button
                                    onClick={this.addContact}
                                    variant="outlined"
                                    color="inherit"
                                >
                                    Guardar
                                </Button>
                            </div>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.modal_edit_contact}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle 
                            id="alert-dialog-title"
                            className='text-center width-600'
                        >{"Editar contacto"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            <div>
                                <Input
                                    label="Nombre del contacto"
                                    placeholder="Nombre del contacto"
                                    value={(this.state.supplier.supplier_contacts[this.state.i||0]||[{}]).contact_name}
                                    onChange={this.handleChangeSupplierContacts('contact_name', this.state.i)}
                                />
                            </div>
                            <div>
                                <Input
                                    label="Telefono de contacto"
                                    placeholder="Telefono de contacto"
                                    value={(this.state.supplier.supplier_contacts[this.state.i||0]||[{}]).contact_phone}
                                    onChange={this.handleChangeSupplierContacts('contact_phone',this.state.i)}
                                />
                            </div>
                            <div>
                                <Input
                                    label="Correo"
                                    placeholder="Correo"
                                    value={(this.state.supplier.supplier_contacts[this.state.i||0]||[{}]).contact_email}
                                    onChange={this.handleChangeSupplierContacts('contact_email',this.state.i)}
                                />
                            </div>
                            <div>
                            <Checkbox
                                label="Contacto principal"
                                checked={(this.state.supplier.supplier_contacts[this.state.i||0]||[{}]).main_contact}
                                onChange={this.handleChangeSupplierContacts('main_contact',this.state.i)}
                            />
                            </div>
                            <div>
                                <Checkbox
                                    label="Representante legal"
                                    checked={(this.state.supplier.supplier_contacts[this.state.i||0]||[{}]).legal_representative}
                                    onChange={this.handleChangeSupplierContacts('legal_representative',this.state.i)}
                                />
                            </div>
                            <div>
                                <Button
                                    onClick={this.editContact}
                                    variant="outlined"
                                    color="inherit"
                                >
                                    Guardar
                                </Button>
                            </div>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </div>
                <div>
                    <Dialog
                        open={this.state.modal_edit_note}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle 
                            id="alert-dialog-title"
                            className='text-center width-600'
                        >{"Edición de nota"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <div>
                                    <Input
                                        placeholder="Nota"
                                        value={(this.state.supplier.notes[this.state.i||0]||[{}]).text}
                                        onChange={this.handleChangeNotes('text', this.state.i)}
                                    />
                                </div>
                                <div>
                                    <Button
                                        onClick={this.editNote}
                                        variant="outlined"
                                        color="inherit"
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </div>

                <div >
                    <Dialog
                        open={this.state.modal_edit_supplier}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        >
                        <DialogTitle 
                            id="alert-dialog-title"
                            className='text-center width-600'
                            >{"Editar proveedor"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            </DialogContentText>
                                <div>
                                    <Input
                                        label="Nombre del proveedor"
                                        placeholder="Nombre del proveedor"
                                        value={supplier.name}
                                        onChange={this.handleChangeSuppier('name')}
                                    />
                                </div>
                                <div>
                                    <Input
                                        label="Ciudad"
                                        placeholder="Ciudad"
                                        value={supplier.city}
                                        onChange={this.handleChangeSuppier('city')}
                                    />
                                </div>
                                <div>
                                    <Input
                                        label="Dirección"
                                        placeholder="Dirección"
                                        value={supplier.address}
                                        onChange={this.handleChangeSuppier('address')}
                                    />
                                </div>
                                <div>
                                    <Select 
                                        label="Tipo de persona"
                                        defaultValue={supplier.person_type}
                                        value={supplier.person_type}
                                        onChange={this.handleChangeSuppier('person_type')}
                                    >
                                        <Option value="Natural" label="Natural" />
                                        <Option value="Juridica" label="Juridica" />
                                    </Select>
                                </div>
                                <div>
                                    <Select
                                        label="Tipo de documento"
                                        defaultValue={supplier.document_type}
                                        value={supplier.document_type}
                                        onChange={this.handleChangeSuppier('document_type')}
                                    >
                                        <Option value="NIT" label="NIT" />
                                        <Option value="C.C" label="C.C" />
                                        <Option value="C.E" label="C.E" />
                                        <Option value="Pasaporte" label="Pasaporte" />
                                    </Select>
                                </div>
                                <div>
                                    <Input
                                        label="Numero de Documento"
                                        placeholder="Numero de Documento"
                                        value={supplier.document_number}
                                        onChange={this.handleChangeSuppier('document_number')}
                                    />
                                </div>
                                <div>
                                    <Select 
                                        label="Tipo de sector"
                                        defaultValue={this.state.supplier.type_sector}
                                        onChange={this.handleChangeSuppier('type_sector')}
                                    >
                                        <Option value="Publico" label="Publico" />
                                        <Option value="Privado" label="Privado" />
                                        <Option value="Mixto" label="Mixto" />
                                    </Select>
                                </div>
                                <div>
                                    <Select 
                                        label="Grandes contribuyentes"
                                        defaultValue={this.state.supplier.big_taxpayers}
                                        onChange={this.handleChangeSuppier('big_taxpayers')}
                                    >
                                        <Option value={false} label="No" />
                                        <Option value={true} label="Si" />
                                    </Select>
                                </div>
                                <div>
                                    <Select 
                                        label="Pertenece a un accionista?"
                                        defaultValue={this.state.supplier.from_shareholder}
                                        onChange={this.handleChangeSuppier('from_shareholder')}
                                    >
                                        <Option value={true} label="Si" />
                                        <Option value={false} label="No" />
                                    </Select>
                                </div>
                                <div>
                                    {(this.state.supplier.from_shareholder == true || this.state.supplier.from_shareholder == 'true') && (
                                        <div>
                                            <div>
                                                <Input
                                                    label="Nombre"
                                                    placeholder="Nombre de accionista"
                                                    value={supplier.shareholder_details.name}
                                                    onChange={this.handleChangeShareholderDetails('name')}
                                                />
                                            </div>
                                            <div>
                                                <Input
                                                    label="Correo"
                                                    placeholder="Correo"
                                                    value={supplier.shareholder_details.email}
                                                    onChange={this.handleChangeShareholderDetails('email')}
                                                />
                                            </div>
                                            <div>
                                                <Input
                                                    label="Telefono"
                                                    placeholder="phone"
                                                    value={supplier.shareholder_details.phone}
                                                    onChange={this.handleChangeShareholderDetails('phone')}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <Button
                                        onClick={this.EditSupplier}
                                        variant="outlined"
                                        color="inherit"
                                    >
                                        Guardar
                                    </Button>
                                </div>
                        </DialogContent>
                    </Dialog>
                </div >
                <div>
                    <ModalEditCategory supplier={supplier} modal_category={this.state.modal_category} handleClose={this.handleClose} setParentStateCategoyEdit = {this.setParentStateCategoyEdit} category= {this.state.category} i={this.state.i} componentDidMountShow={this.componentDidMount}/>
                </div>
                <div>
                    <ModalAddCategory modal_add_category={this.state.modal_add_category} handleClose={this.handleClose} supplier={supplier} componentDidMountShow={this.componentDidMount}/>
                </div>
                <div>
                    <ModalShowShareholder modal_show_shareholder={this.state.modal_show_shareholder} handleClose={this.handleClose} supplier={supplier}/>
                </div>
                {/* <div>
                    <ModalChangeLogo modal_change_logo={this.state.modal_change_logo} handleClose={this.handleClose} supplier={supplier}/>
                </div> */}
                <div>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={this.state.open_notification}
                        autoHideDuration={6000}
                        onClose={this.handleClose}
                    >
                    <MySnackbarContentWrapper
                        onClose={this.handleClose}
                        variant="success"
                        message={this.state.message}
                    />
                    </Snackbar>
                </div>
                <div>
                    <S3Logo setParentStateLogo = {this.setParentStateLogo}/>
                </div>
            </div>
        )
    }
};