import React, { Component } from 'react';
import { Create, SimpleForm, TextInput, SelectInput } from 'react-admin';


const choices = [
    { value: 'superAdmin', description: 'Super Administrador' },
    { value: 'admin', description: 'Administrador' },
];

export default class UserCreate extends Component{

    render(){
        return (
            <Create {...this.props}>
                <SimpleForm>
                    <TextInput source="first_name" label="Nombres" />
                    <TextInput source="last_name" label="Apellidos" />
                    <TextInput source="email" label="correo"/>
                    <TextInput source="password" label="Contraseña" type="password"/>
                    <SelectInput source="role" choices={choices} optionText="description" optionValue="value" />
                </SimpleForm>
            </Create>
        )
    }
}