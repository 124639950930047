import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import {Dashboard,Apps, People, Person, VerifiedUser} from '@material-ui/icons';
import { withRouter } from 'react-router-dom';

import {
    translate,
    DashboardMenuItem,
    MenuItemLink,
    Responsive,
} from 'react-admin';
import { gray } from 'ansi-colors';

class Menu extends Component {
    state = {
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }));
    };

    render() {
        const { onMenuClick, logout } = this.props;
        return (
            <div>
                {/* <DashboardMenuItem onClick={onMenuClick} /> */}
                <MenuItemLink className={"menuItems"}
                    to={`/`}
                    primaryText={'Proveedores'}
                    leftIcon={<People />}
                    onClick={onMenuClick}
                />
                <MenuItemLink className={"menuItems"}
                    to={`/categories`}
                    primaryText={'Categorias'}
                    leftIcon={<Apps />}
                    onClick={onMenuClick}
                />
                <MenuItemLink className={"menuItems"}
                    to={`/quotes`}
                    primaryText={'Cotizaciones'}
                    leftIcon={<Dashboard />}
                    onClick={onMenuClick}
                />
                <MenuItemLink className={"menuItems"}
                    to={`/suppliers`}
                    primaryText={'P. Inactivos'}
                    leftIcon={<VerifiedUser style={{color:'#f9f9f9'}} />}
                    onClick={onMenuClick}
                />
                {/* {
                    localStorage.getItem('role') == 'superAdmin' && ( */}
                        <MenuItemLink className={"menuItems"}
                            to={`/users`}
                            primaryText={'Usuarios'}
                            leftIcon={<Person />}
                            onClick={onMenuClick}
                        />
                    {/* )
                } */}
                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    locale: state.i18n.locale,
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);

export default enhance(Menu);
