import React, { Component } from 'react';
import { List, Datagrid, TextField, DateField, DeleteButton, ShowButton } from 'react-admin';



const AprovalStatus = ({ record = {} }) => {
    let color = '';
    color = record.approval_status == "Abierto" ? "green": "red"; 
    return <span style={{color:color}}>{record.approval_status}</span>
}

// const Status = ({ record = {} }) =>{
//     return <span>{record.active == 0 ? 'Inactivo' : record.active == 1 ? 'Activo' : 'Pendiente por revision'}</span>;
// };


export default class QuotesList extends Component{
    render(){
        return(
        <List {...this.props} exporter={false}>
            <Datagrid>
                <TextField source="process_name" label="Nombre"/>
                <DateField source="end_date_process" label="Fecha fin del proceso" showTime />
                <AprovalStatus label="Estado de aprobación"/>
                <ShowButton label="Mostrar" />
                <DeleteButton label="Eliminar"/>
            </Datagrid>
        </List>
        )
    }
};
