import React, {Component} from 'react';
import Panel from 'muicss/lib/react/panel';
import Input from 'muicss/lib/react/input';
import { Formik } from 'formik';
import Option from 'muicss/lib/react/option';
import Select from 'muicss/lib/react/select';
import Button from 'muicss/lib/react/button';
import Textarea from 'muicss/lib/react/textarea';
import feathersClient from '../../feathersClient';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];


export class SupplierCreate extends Component{

    state={
        supplier:{
            name:'',
            city:'',
            address:'',
            person_type:'Natural',
            document_type: 'C.C',
            document_number: '',
            from_shareholder:'false',
            shareholder_details:{
                name:'',
                email:'',
                phone:'',
                note:''
            },
            big_taxpayers:false,
            type_sector:'',
            supplier_contacts:[],
            supplier_notes:[],
            categories:[],
            categoriesList:[]
        },
        name:[]
    }

    componentDidMount(){
        feathersClient.service('categories').find().then((categories)=>{
            this.setState({supplier:{
                ...this.state.supplier,
                categoriesList: categories.data}})
        })
    }

    componentWillMount() {
        this.setState({supplier:{
            ...this.state.supplier,
            supplier_contacts : [{}],
            supplier_notes:[{}]
        }})
    }


    handleChangeCategories = event => {
        this.setState({ name: event.target.value });

        // this.setState({ supplier:{ ...this.state.supplier, 
        //     categories: event.target.value
        // }});

        // console.log(this.state,'------------');
      };

    handleChange = name => event => {
        this.setState({supplier:{...this.state.supplier, [name]: event.target.value }});
        console.log(this.state,'-----------------');
    }

    handleChangeShareholderDetails = name => event => {
        this.setState({supplier:{
                ...this.state.supplier,
            shareholder_details:{
                ...this.state.supplier.shareholder_details, [name]: event.target.value
            }
        }})

        console.log(this.state,'-----------')
    }

    addNewSuppliertContacts = async() => {
        const contact_data = JSON.parse(JSON.stringify(this.state.supplier.supplier_contacts));
        contact_data.push({});

        this.setState({supplier:{
            ...this.state.supplier,
            supplier_contacts : contact_data
        }})

        console.log(this.state.supplier,'-----------------');
    }

    handleChangeSupplierContacts = (name, i) => event => {
        const contact_data = JSON.parse(JSON.stringify(this.state.supplier.supplier_contacts));

        !contact_data[i] && contact_data.push({});
        contact_data[i][name] = event.target.value;
        console.log(contact_data[i],'----------')

        this.setState({supplier:{
            ...this.state.supplier,
            supplier_contacts : contact_data
        }})

        console.log(this.state,'-----------------');
    }

    handleChangeSupplierNotes = (name, i) => event => {
        const note = JSON.parse(JSON.stringify(this.state.supplier.supplier_notes));

        !note[i] && note.push({});
        note[i][name] = event.target.value;
        console.log(note[i],'----------')

        this.setState({supplier:{
            ...this.state.supplier,
            supplier_notes : note
        }})

        console.log(this.state,'-----------------');
    }

    addNewNote = async() => {
        const note = JSON.parse(JSON.stringify(this.state.supplier.supplier_notes));
        note.push({});

        this.setState({supplier:{
            ...this.state.supplier,
            supplier_notes : note
        }})

        console.log(this.state.supplier,'-----------------');
    }

    onCreate = async () => {
        const {shareholder_details} = this.state.supplier;
        // console.log(this.state.supplier.from_shareholder)
        if(this.state.supplier.from_shareholder == 'true'){
            if(!shareholder_details.name || !shareholder_details.email || !shareholder_details.phone){
                alert('Si es de un accionista debes proporcionar la información')
            }
        }

        console.log(this.state);
        await feathersClient.service('suppliers').create(this.state.supplier);
    }

    


    render(){
        const {supplier} = this.state;

        console.log(supplier.categoriesList,'------------');

        return(
            <div>
                <Panel>
                    <Formik >
                        <div>
                            <h2>Crear Proveedor </h2>
                            <div>
                                <Input
                                    label="Nombre"
                                    placeholder="Nombre del proveedor"
                                    value={supplier.name}
                                    onChange={this.handleChange('name')}
                                />
                            </div>
                            <div>
                                <Input
                                    label="Ciudad"
                                    placeholder="Ciudad del proveedor"
                                    value={supplier.city}
                                    onChange={this.handleChange('city')}
                                />
                            </div>
                            <div>
                                <Input
                                    label="Dirección"
                                    placeholder="Dirección"
                                    value={supplier.address}
                                    onChange={this.handleChange('address')}
                                />
                            </div>
                            <div>
                                <Select 
                                    label="Tipo de persona"
                                    defaultValue="Natural"
                                    onChange={this.handleChange('person_type')}
                                >
                                    <Option value="Natural" label="Natural" />
                                    <Option value="Juridica" label="Juridica" />
                                </Select>
                            </div>
                            <div>
                                <Select
                                    label="Tipo de documento"
                                    defaultValue="C.C"
                                    onChange={this.handleChange('document_type')}
                                >
                                    <Option value="NIT" label="NIT" />
                                    <Option value="C.C" label="C.C" />
                                    <Option value="C.E" label="C.E" />
                                    <Option value="Pasaporte" label="Pasaporte" />
                                </Select>
                            </div>
                            <div>
                                <Input
                                    label="Numero de documento"
                                    placeholder="Numero de documento"
                                    value={supplier.document_number}
                                    onChange={this.handleChange('document_number')}
                                />
                            </div>
                            <div>
                                <Select 
                                    label="Tipo de sector"
                                    onChange={this.handleChange('type_sector')}
                                >
                                    <Option value="Publico" label="Publico" />
                                    <Option value="Privado" label="Privado" />
                                    <Option value="Mixto" label="Mixto" />
                                </Select>
                            </div>
                            <div>
                                <Select 
                                    label="Grandes contribuyentes"
                                    onChange={this.handleChange('big_taxpayers')}
                                >
                                    <Option value={true} label="Si" />
                                    <Option value={false} label="No" />
                                </Select>
                            </div>
                            <div>
                                <Select 
                                    label="Estado"
                                    defaultValue=""
                                    onChange={this.handleChange('status')}
                                >
                                    <Option value="true" label="Activo" />
                                    <Option value="false" label="Inactiva" />
                                </Select>
                            </div>
                            <div>
                                <Select 
                                    label="Pertenece a un accionista?"
                                    defaultValue={false}
                                    onChange={this.handleChange('from_shareholder')}
                                >
                                    <Option value={true} label="Si" />
                                    <Option value={false} label="No" />
                                </Select>
                            </div>
                            <div>
                                {this.state.supplier.from_shareholder == 'true' && (
                                    <div>
                                        <div>
                                            <Input
                                                label="Nombre"
                                                placeholder="Nombre de accionista"
                                                value={supplier.shareholder_details.name}
                                                onChange={this.handleChangeShareholderDetails('name')}
                                            />
                                        </div>
                                        <div>
                                            <Input
                                                label="Correo"
                                                placeholder="Correo"
                                                value={supplier.shareholder_details.email}
                                                onChange={this.handleChangeShareholderDetails('email')}
                                            />
                                        </div>
                                        <div>
                                            <Input
                                                label="Telefono"
                                                placeholder="phone"
                                                value={supplier.shareholder_details.phone}
                                                onChange={this.handleChangeShareholderDetails('phone')}
                                            />
                                        </div>
                                        <div>
                                            <Textarea
                                                label="Nota"
                                                placeholder="Nota"
                                                value={supplier.shareholder_details.notes}
                                                onChange={this.handleChangeShareholderDetails('note')}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div>
                                <h3>Datos de contacto</h3>
                            </div>
                            {supplier.supplier_contacts.map((contact, i)=>
                                <div key={i}>
                                    <div>
                                        <Select 
                                            label="Contacto principal"
                                            defaultValue={false}
                                            onChange={this.handleChangeSupplierContacts('main_contact', i)}
                                        >
                                            <Option value={true} label="Si" />
                                            <Option value={false} label="No" />
                                    </Select>
                                    </div>
                                    <div>
                                        <Input
                                            label="Nombre de contacto"
                                            placeholder="Nombre de contacto"
                                            onChange={this.handleChangeSupplierContacts('contact_name', i)}
                                        />
                                    </div>
                                    <div>
                                        <Input
                                            label="Telefono de contacto"
                                            placeholder="Telefono de contacto"
                                            onChange={this.handleChangeSupplierContacts('contact_phone', i)}
                                        />
                                    </div>
                                    <div>
                                        <Input
                                            label="Correo de contacto"
                                            placeholder="Correo de contacto"
                                            onChange={this.handleChangeSupplierContacts('contact_email', i)}
                                        />
                                    </div>
                                    <div className="btn-line"></div>
                                </div>
                            )}
                            <div>
                                <Button
                                    color="primary"
                                    onClick={this.addNewSuppliertContacts}
                                >Agregar</Button>
                            </div>
                            <div>
                                <h3>Notas</h3>
                            </div>
                            {supplier.supplier_notes.map((note, i)=>
                            <div>
                                <div key={i}>
                                    <div>
                                        <Input
                                            label="Descripción"
                                            placeholder="Descripción"
                                            onChange={this.handleChangeSupplierNotes('text', i)}
                                        />
                                    </div>
                                </div>
                            </div>
                            )}
                            <div>
                                <Button
                                    color="primary"
                                    onClick={this.addNewNote}
                                >Agregar</Button>
                            </div>
                            <div>
                            <FormControl>
                                <InputLabel htmlFor="select-multiple-chip">Categorias</InputLabel>
                                <Select
                                    multiple
                                    value={this.state.name}
                                    onChange={this.handleChangeCategories}
                                    input={<Input id="select-multiple-chip" />}
                                    renderValue={selected => (
                                        <div>
                                        {selected.map(value => (
                                            <Chip key={value} label={value}/>
                                        ))}
                                        </div>
                                    )}
                                    // MenuProps={MenuProps}
                                >
                                    {names.map(name  => (
                                    <MenuItem key={name } value={name }>
                                        {name}
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            </div>
                            <div>
                                <Button 
                                    color="primary"
                                    onClick={this.onCreate}
                                >Guardar</Button>
                            </div>
                        </div>
                    </Formik>
                </Panel>
            </div>
        );
    }
}


// {
//     suppliersQuotes.map(suppliersQuote =>
//         <Grid item xs={12} md={4} style={{paddingBottom:10, paddingLeft:10, paddingRight:10}}>
//         <a href={`/#/suppliers/${suppliersQuote.supplier._id}/show`}>
//             <Card className="main-card">
//                 <CardContent className ={'cardContent'} style={{marginBottom: -28}}>
//                     <div className="card-supplier-top">
//                         <div>
//                             <img src={`https://proveedores-puerta-de-oro.s3.us-east-2.amazonaws.com/${suppliersQuote.supplier.logo}`} style={{objectFit:"contain", backgroundColor: "black"}} height="90px" width="90px"/>
//                         </div>
//                         <div className="card-supplier-top-text">
//                             <p className="bold-color-main">{suppliersQuote.supplier.name}</p>
//                             <p className="color-text" style={{marginBottom:0, marginTop:-10}}>{suppliersQuote.supplier.city}</p>
//                             <p className="color-text">Persona {suppliersQuote.supplier.person_type}</p>
//                         </div>
//                         <div>
//                             <p className= {suppliersQuote.supplier.from_shareholder ? "card-supplier-top-star" : "card-supplier-top-start-hidden" } >
//                                 <Star/> Accionista
//                             </p>
//                         </div>
//                     </div>
//                 </CardContent>
//                 <CardContent className="cardContent-chips">
//                     <div>
//                         {
//                             (suppliersQuote.supplier.categories.length > 0) && (
//                                 suppliersQuote.supplier.categories.map((category)=>{
//                                     return(
//                                         <div>
//                                         {/* <p className="chip_card_category"> {category.name}</p> */}
//                                         {
//                                             (category.tags.length > 0) && (
//                                                 category.tags.map((tag)=>{
//                                                     return(
//                                                         <Chip label={tag.name} variant="outlined" className="chip_green"/>
//                                                     )
//                                                 })
//                                             )
//                                         }
//                                         </div>
//                                     )
//                                 })
//                             )
//                         }
//                     </div>
//                 </CardContent>
//                 <CardActions className={"cardAction"}>
//                     <div>
//                             {
//                                 suppliersQuote.supplier.supplier_contacts.map((contact)=>{
//                                     return(
//                                         contact.main_contact == true && (
//                                             <div>
//                                                 <p><b>Contacto principal</b></p>
//                                                 <p style={{marginTop: -12}}>{contact.contact_name} - Cel. {contact.contact_phone} - {contact.contact_email}</p>
//                                             </div>
//                                         )
//                                     )
//                                 })
//                             }
//                     </div>
//                 </CardActions>
//             </Card>
//         </a>
//     </Grid>
// )
// }