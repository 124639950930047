import React from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton, DeleteButton, SingleFieldList, ChipField, ReferenceArrayField } from 'react-admin';


const CategoryList = (props) => (
    <List {...props} exporter={false}>
        <Datagrid>
            <TextField source="name" label="Nombre"/>
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default CategoryList;