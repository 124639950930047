import React from 'react';

const Logo = props => (
    <img src="http://www.puertadeoro.org/wp-content/uploads/2018/02/logo.png" style={{width: 193}} ></img>
);






export default Logo;
