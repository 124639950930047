
import React, { Component, useState  } from 'react';
import Input from 'muicss/lib/react/input';
import Panel from 'muicss/lib/react/panel';
import {InstantSearch, Configure, connectHits, connectStateResults, connectStats, MenuSelect, Pagination, ToggleRefinement} from 'react-instantsearch-dom';
import {Card, CardActions, CardContent, Button, Grid } from '@material-ui/core';
import feathersClient from '../feathersClient';
import Checkbox from '@material-ui/core/Checkbox';
import ModalCreateSupplier from '../Components/suppliers/Components/modal-create-supplier';
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import {SnackbarContent, IconButton} from '@material-ui/core/';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconShow from '@material-ui/icons/RemoveRedEye'

const Stats = connectStats(({ nbHits, processingTimeMS }) => {
    return <span className="mb-5">{nbHits} resultados en {processingTimeMS} milisegundos.</span>;
});

const Content = connectStateResults(
    ({ searchState, searchResults }) =>
        (searchResults && searchResults.nbHits === 0) && (
            <div className="container">
                No encontramos ningun resultado para tu busqueda.
            </div>
        ));

const CustomHits = connectHits(({ hits, ...props }) => {
    return (
        <div>
            <Grid container spacing={16} className="a">
                {
                    hits.map(hit =>
                        <Grid key={hit._id} item xs={12} md={4} className="b">
                            <Card className="main-card">
                                <CardContent className ={'cardContent'} style={{marginBottom: -28}}>
                                    <div className="card-supplier-top">
                                        <div>
                                            <img src={`https://proveedores-puerta-de-oro.s3.us-east-2.amazonaws.com/${hit.logo? hit.logo :'static/no-image.jpg'}`} style={{objectFit:"contain", backgroundColor: "black"}} height="90px" width="90px"/>
                                        </div>
                                        <div className="card-supplier-top-text">
                                            <p className="bold-color-main text-name-suppliers">{hit.name}</p>
                                            <p className="color-text capitalize" style={{marginBottom:0, marginTop:-10}}>{hit.city}</p>
                                            <p className="color-text ">Persona {hit.person_type}</p>
                                        </div>
                                        <div>
                                            <a href={`/#/suppliers/${hit._id}/show`} style={{color: '#333'}}>
                                                <IconShow color='inherit' />
                                            </a>
                                            <Checkbox
                                                className="checkBoxDasboard"
                                                onChange={(ev) => props.setStateParent({"checkbox": hit._id})}
                                                value="checkedB"
                                                color="default"
                                            />
                                        </div>
                                    </div>
                                </CardContent>

                                <CardActions className={"cardAction"}>
                                    <div>
                                        {
                                            hit.supplier_contacts.map((contact, i)=>{
                                                return(
                                                    contact.main_contact == true && (
                                                        <div key={i}>
                                                            <p><b>Contacto principal</b></p>
                                                            <p style={{marginTop: -12}}>{contact.contact_name} - Cel. {contact.contact_phone} - {contact.contact_email}</p>
                                                        </div>
                                                    )
                                                )
                                            })
                                        }
                                    </div>
                                </CardActions>
                            </Card>
                        </Grid>
                    )
                }
            </Grid>
        </div>
    )
});



class Dashboard extends Component {

    constructor(props){
        super(props);
        this.state ={
            search:"",
            // categoryList:[],
            category:"",
            quotes:[{}],
            open_modal_create_supplier: false,
            checkedB: true,
            suppliers_ids:[],
            suppliers_ids_quantity: null,
            quote_id:null,
            notification:{
                openNotification: false,
                messageNotification: '',
                typeNotification: ''
            }
        }
    }

    componentDidMount(){

        feathersClient.service('quotes').find({
            query:{
                approval_status: 'Abierto'
            }, paginate:false
        }).then((q)=>{
            this.setState({quotes:q.data})
        })
        
    }

    setStateParent = async (values) =>{

        let suppliers_ids = JSON.parse(JSON.stringify(this.state.suppliers_ids));

        if(!suppliers_ids.includes(values.checkbox)){
            suppliers_ids.push(values.checkbox);
        }else{
            suppliers_ids = suppliers_ids.filter(value => !value.includes(values.checkbox));
        }
        
        this.setState({suppliers_ids: suppliers_ids, suppliers_ids_quantity: suppliers_ids.length})

        // console.log(this.state)
    };

    handleClickOpen = () => {
        this.setState({ open_modal_create_supplier: true });
    };

    handleClose = () => {
        this.setState({ open_modal_create_supplier: false });
    };
    
    search = (event) => {
        this.setState({ search: event.target.value })
    }

    handleChange = name => event => {
        this.setState({[name]: event.target.value });
    }

    onCreate = async () =>{
        const { quote_id, suppliers_ids } = this.state;
        const params = {
            quote_id: quote_id,
            suppliers_ids:suppliers_ids
        }

        feathersClient.service('suppliers-quotes').create(params).then(()=>{
            this.setState({notification:{openNotification:true, messageNotification:"Proveedores invitados al proceso", typeNotification:"succes"}})
            
            setTimeout(
                function() {
                    this.setState({notification:{openNotification: false}});
                    window.location.replace(`${window.location.origin}/#/quotes/${quote_id}/show`)
                }
                .bind(this),
                3000
              );

        }).catch((error)=>{
            this.setState({notification:{openNotification:true, messageNotification:error.message, typeNotification:"FeathersError"}})
        });

        
    }

    handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({notification:{
          openNotification:false}
        })
      }

    render() {
        // const { match } = this.props;
        const { search, categoryList, suppliers_ids_quantity, quotes } = this.state;
        return(
            <div>
                <div>
                    <InstantSearch
                        appId="PFQ770AT6G"
                        apiKey="325176ce7114876af8c9811bb0674102"
                        indexName="suppliers"
                        >
                        <Configure 
                            query={search}
                            facets={['categories._id', 'shareholder_details.email', 'shareholder_details.name']}
                            facetFilters={[`categories._id:${(this.state.category|| '').replace(/-/g, ' ')}`]}
                            hitsPerPage={15}
                        />
                        <div className="container">
                                {
                                    (suppliers_ids_quantity > 0) && (
                                        <Panel>
                                            <Grid container spacing={16}>
                                                    <Grid item xs={12} md={4}>
                                                        <div>
                                                            <p>Tienes {suppliers_ids_quantity} seleccionados</p>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <div>
                                                            <Select 
                                                                name="input"
                                                                label="Seleccione proceso"
                                                                onChange={this.handleChange('quote_id')}
                                                            >
                                                                <Option
                                                                    value={undefined}
                                                                    label="No seleccionado"
                                                                />
                                                                {
                                                                    quotes.map((quote, i)=>
                                                                        <Option key={i} value={quote._id} label={quote.process_name} />
                                                                    )
                                                                }
                                                            </Select>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <div>
                                                            <Button
                                                                onClick={this.onCreate}
                                                                variant="outlined"
                                                                color="inherit"
                                                            >
                                                            Enviar invitación
                                                            </Button>
                                                        </div>
                                                    </Grid>
                                            </Grid>
                                        </Panel>
                                    )
                                }
                            <Panel>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} md={4}>
                                        <Input
                                            className="search-suppliers"
                                            placeholder="Buscar por Nombre, Categoria, Tags etc"
                                            label="Buscador de proveedores"
                                            onChange={(value) => this.search(value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}> 
                                        <div>
                                            <MenuSelect
                                                attribute={'categories.name'}
                                                limit={10}
                                                translations={{
                                                    seeAllOption: 'Categorias',
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <div>
                                            <ToggleRefinement
                                                attribute={'from_shareholder'}
                                                label={'Accionistas'}
                                                value={true}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <Button
                                            variant="contained"
                                            className="button-add-suppliers"
                                            onClick={this.handleClickOpen}
                                        >+ AGREGAR PROVEEDOR</Button>                                       
                                    </Grid>
                                </Grid>
                            </Panel>
                            <div>
                                <Content />
                                <CustomHits setStateParent = {this.setStateParent}/>
                            </div>
                        </div>
                        <div className="mt-15">
                          <Pagination />
                        </div>
                    </InstantSearch>
                </div>
                <div>
                    <ModalCreateSupplier open_modal_create_supplier={this.state.open_modal_create_supplier} handleClose={this.handleClose}/>
                </div>
                <div>
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    open={this.state.notification.openNotification}
                  >
                    <SnackbarContent
                        aria-describedby="client-snackbar"
                        style={this.state.notification.typeNotification == "FeathersError" ? {backgroundColor:'red'}: {backgroundColor: 'black'}}
                        message={
                          <span id="client-snackbar" >
                            {this.state.notification.messageNotification}
                          </span>
                        }
                        action={[
                          <IconButton 
                            key="close" 
                            aria-label="Close" 
                            color="inherit" 
                            onClick={this.handleCloseNotification}>
                            <CloseIcon />  
                          </IconButton>,
                        ]}
                      />
                  </Snackbar>
              </div>
            </div>
        );
    }
}

export default Dashboard;