import React, { Component } from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton } from 'react-admin';

export default class UserList extends Component {
    render() {
        return(
            <List {...this.props} exporter={false}>
                <Datagrid>
                    <TextField source="_id" />
                    <TextField source="first_name" label="Nombre"/>
                    <TextField source="last_name" label="Apellido"/>
                    <TextField source="email" label="email"/>
                    <EditButton />
                    <DeleteButton />
                </Datagrid>
            </List>
        );
    }
}