import React, { Component } from 'react';
import { Edit, SimpleForm, TextInput, LongTextInput, SelectInput } from 'react-admin';
import feathersClient from '../../feathersClient';
import { Grid, Snackbar, SnackbarContent, Button, Divider ,ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails,ExpansionPanelActions, Typography } from '@material-ui/core';
import { Star, CloudDownload } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close'
import { DateTimeInput } from 'react-admin-date-inputs';

const choices = [
    { value: 'Abierto', description: 'Abierto' },
    { value: 'Cerrado', description: 'Cerrado' },
];
export default class QuotesShow extends Component{

    constructor(props){
        super(props)
        this.state={
            suppliersQuotes:[{
                supplier:{
                    categories:[],
                    supplier_contacts:[]
                },
                quote:{
                    _id:''
                },
                files:[{}]
            }],
            notification:{
                openNotification:false,
                messageNotification:'',
                typeNotification:''
            }
        }
    }

    componentDidMount(){
        const {id} = this.props.match.params
        feathersClient.service('suppliers-quotes').find({
            query:{
                "quote._id": id,
                $limit: 1000
            }, 
        }).then((result)=>{
            this.setState({suppliersQuotes: result.data})
        })

    }

    DeleteSuppier(i){
        const suppliersQuotes = JSON.parse(JSON.stringify(this.state.suppliersQuotes));

        const supplierDell = suppliersQuotes.splice(i,1);

        this.setState({suppliersQuotes:suppliersQuotes});

        feathersClient.service('suppliers-quotes').remove(supplierDell[0]._id).then(()=>
            this.setState({notification:{messageNotification:'Proveedor eliminado del proceso', typeNotification:'succes', openNotification:true}})
        ).catch((error)=>{
            this.setState({notification:{messageNotification:error.message, typeNotification:'FeathersError', openNotification:true}})
        })
    }

    acceptSupplier(i) {
        const suppliersQuote = this.state.suppliersQuotes
        const supplierAccept = suppliersQuote[i]
        supplierAccept.accepted = true

        feathersClient.service('supplier-quote-accept')
            .create({ supplier_quote_id: supplierAccept._id })
            .then(() => {
                this.setState({
                    notification: { messageNotification: 'Proveedor aceptado', typeNotification: 'succes', openNotification: true },
                })
            })
            .catch((err) => {
                this.setState({ notification: { messageNotification: err.message || 'Algo ha salido mal', typeNotification: 'FeathersError', openNotification: true } })
            })
    }

    handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState({notification:{
          openNotification:false}
        })
      }

    render(){
        const {suppliersQuotes} = this.state;
        console.log(suppliersQuotes)
        var getUrl = window.location;
        var baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
        return(
            <div>
                <div>
                    <Grid container spacing={16}>
                        <Grid item xs={12} md={6}>
                            <Edit  {...this.props} title={"Editar cotización"}>
                                <SimpleForm>
                                    <TextInput source="process_name" label="Nombre del proceso" />
                                    <DateTimeInput source="end_date_process" label="Fecha fin cotización" options={{ format: 'dd/MM/YYYY, HH:mm:ss', ampm: true, clearable: true }} />
                                    <SelectInput source="approval_status" choices={choices} optionText="description" optionValue="value" label="Estado de aprobación" />
                                    <LongTextInput source="description" label="Descripción"/>
                                </SimpleForm>
                            </Edit>
                        </Grid>
                    </Grid >
                </div>
                <Grid container spacing={16}>
                    <Grid item xs={12} md={12} style={{paddingBottom:10, paddingLeft:10, paddingRight:10}}>
                        {
                            (suppliersQuotes.length > 0) && (
                                <h4>Proveedores invitados al proceso ID: <a href={`${baseUrl}/#/quotes/${suppliersQuotes[0].quote._id}`}>{suppliersQuotes[0].quote._id}</a></h4>
                            )
                        }
                    </Grid>
                </Grid>
                {
                    (suppliersQuotes.length === 0) && (
                        <p>No se han invitado Proveedores en este proceso</p>
                    )
                }
                <Grid container spacing={16}>
                    <Grid item xs={12} md={12} style={{paddingBottom:10, paddingLeft:10, paddingRight:10}}>
                        {
                            suppliersQuotes.map((suppliersQuote, i) =>{
                                return(
                                    <div key={i} className="expancionPanelMain">
                                    <ExpansionPanel defaultExpanded >
                                        <ExpansionPanelSummary
                                            className="expansionPanelHeader"
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1c-content"
                                            id="panel1c-header"
                                        >
                                        <div >
                                            <Typography >{`${suppliersQuote.supplier.name} - ${suppliersQuote.supplier.city} - Fecha de invitación: ${moment(suppliersQuote.createdAt).format()}`} <span className={suppliersQuote.supplier.from_shareholder ? "card-supplier-top-star" : "card-supplier-top-start-hidden" }><Star/> Accionista</span></Typography>
                                        </div>
                                        <div >
                                            {/* <Typography >Select trip destination</Typography> */}
                                        </div>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails >
                                        <div>
                                                <div style={{ display: 'flex', gap: 16 }} >
                                           {suppliersQuote.accepted && <span style={{
                                               background: 'green',
                                               color: 'white',
                                               padding: '8px',
                                               borderRadius: '5px'
                                            }}>Aceptado</span>} 
                                                    {!suppliersQuote.accepted && (
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => this.acceptSupplier(i)}
                                                    >
                                                        Aceptar cotización
                                                    </Button>
                                                    )}
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        style={{backgroundColor:'red'}}
                                                        onClick={() => this.DeleteSuppier(i)}
                                                    >
                                                        Descartar cotización
                                                    </Button>
                                                </div>
                                            <div >
                                                <h4>Archivos</h4>
                                                {
                                                    suppliersQuote.files.map((file, i)=>{
                                                        return(
                                                            <div key={i}>
                                                                <Typography className="show-files" >
                                                                    <div className="text-show-files">
                                                                        {`${file.name} - Fecha de subida: ${moment(file.createdAt).format()}`} 
                                                                    </div>
                                                                    <Button variant="outlined" target="_blank" className="btn-download-file"
                                                                        href={`https://proveedores-puerta-de-oro.s3.us-east-2.amazonaws.com/${file.path}`}
                                                                    >
                                                                        <CloudDownload ></CloudDownload>
                                                                    </Button>
                                                                </Typography>
                                                                <Divider />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        </ExpansionPanelDetails>
                                        <Divider />
                                        <ExpansionPanelActions>
                                        </ExpansionPanelActions>
                                    </ExpansionPanel>
                                </div>
                                )
                            })
                        }
                       
                    </Grid>
                </Grid>
                <div>
                    <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={this.state.notification.openNotification}
                    >
                    <SnackbarContent
                        aria-describedby="client-snackbar"
                        style={this.state.notification.typeNotification == "FeathersError" ? {backgroundColor:'red'}: {backgroundColor: 'black'}}
                        message={
                            <span id="client-snackbar" >
                            {this.state.notification.messageNotification}
                            </span>
                        }
                        action={[
                            <IconButton 
                            key="close" 
                            aria-label="Close" 
                            color="inherit" 
                            onClick={this.handleCloseNotification}>
                            <CloseIcon />  
                            </IconButton>,
                        ]}
                        />
                    </Snackbar>
                </div>
            </div>
        )
    }
}