import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {DialogTitle, DialogContent, DialogContentText, Button, Snackbar, SnackbarContent} from '@material-ui/core';
import feathersClient from '../../../feathersClient';
import { Chip } from '@material-ui/core';
import { Done} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default class ModalEditCategory extends Component {

    state = {
            supplier:{
            notes:[],
            supplier_contacts:[],
            files:[],
            categories:[
                {
                    tags:[]
                }
            ]
        },
        category:{
            name:'',
            tags:[]
        },
        category_now:{
            name:'',
            tags:[]
        },
        i:0,
        category_name:'',
        notification:{
            open: false,
            message:'',
            type:''
        }
    }

    componentWillReceiveProps(nextProps){

        feathersClient.service('categories').get(nextProps.category._id).then((category)=>{
            this.setState({category: category, category_now: this.props.category})
        });

        this.setState({supplier:this.props.supplier})
    }

    editTags = async (tag, exists) =>{

        let newCategoryNowTags = [];

        if (exists) {
            newCategoryNowTags = this.state.category_now.tags.filter(it => it._id !== tag._id);
        } else {
            newCategoryNowTags = this.state.category_now.tags;
            newCategoryNowTags.push(tag);
        }

        this.setState({
            category_now: {
                ...this.state.category_now,
                tags: newCategoryNowTags
            }
        });
        console.log(this.state)
    }

    saveCategoryTags = async ()=>{        

        const { supplier } = this.state;
        
        const categories = supplier.categories;
        let oldCategories = [];

        for (let index = 0; index < categories.length; index++) {
            const category = categories[index];

            if(category._id == this.state.category_now._id){
                oldCategories = categories.filter(it => it._id !== category._id);

                oldCategories.push(this.state.category_now);

                this.setState({
                    supplier:{
                        categories:[...oldCategories],
                        ...this.state.supplier
                    }
                });
            }
            
        }

        const params = {
            categories:[
                ...oldCategories
            ]
        }

        feathersClient.service('suppliers').patch(this.state.supplier._id,params).then((response)=>{
            this.setState({supplier:response,notification:{open:true,type:'success', message:'Guardado'}});
            
        }).catch((error)=>
            this.setState({notification:{open:true, type:'FeathersError', message:error.message}})
        )

        setTimeout(
            function() {
                this.setState({notification:{open:false}})
            }
            .bind(this),
            2000
        );
        this.props.handleClose();
        this.props.componentDidMountShow();
    }

    render(){
        return(
            <div >
                <Dialog
                    open={this.props.modal_category}
                    onClose={this.props.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">{`${this.props.category.name}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        </DialogContentText>
                            <div>
                                {
                                    this.state.category.tags.map((tag, i)=>{
                                        const exists = !!this.state.category_now.tags.filter(it2 => it2._id === tag._id).length;
                                        return (
                                            <Chip 
                                                key={i} 
                                                label={`${tag.name} `} 
                                                variant="outlined" 
                                                className={`chip_card ${exists && 'chip_green'}`} 
                                                onClick={() =>this.editTags(tag, exists)}
                                            />
                                        )
                                    })
                                }
                            </div>
                            <br/>
                            <div>
                                <Button
                                    onClick={this.saveCategoryTags}
                                    variant="outlined"
                                    color="inherit">
                                    Guardar
                                </Button>
                            </div>
                    </DialogContent>
                </Dialog>
                <div>
                    <snackBar message={this.state.message} openSnackBar={this.state.open_snack_bar}/>
                </div>
                <div>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={this.state.notification.open}
                        autoHideDuration={3000}
                        onClose={this.handleClose}
                    >
                    <MySnackbarContentWrapper
                        onClose={this.handleClose}
                        style={this.state.notification.type == "FeathersError" ? {backgroundColor:'red'}: {backgroundColor: 'black'}}
                        message={this.state.notification.message}
                    />
                    </Snackbar>
                </div>
            </div>
        )
    }
}

const MySnackbarContentWrapper = (MySnackbarContent);

function MySnackbarContent(props) {
    const { classes, className, message, onClose, variant, ...other } = props;
  
    return (
      <SnackbarContent
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" >
            <Done />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon/>
          </IconButton>,
        ]}
        {...other}
      />
    );
  }
