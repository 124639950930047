import React, {Component} from 'react';
import Input from 'muicss/lib/react/input';
import {Grid, Button, Chip} from '@material-ui/core';
import Panel from 'muicss/lib/react/panel';
import feathersClient from '../../feathersClient';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close'
import {Delete} from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const ENTER_KEY = 13;
const COMMA_KEY = 188;
const BACKSPACE_KEY = 8;


export default class CategoryCreate extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      name:'',
      tags: [],
      value: "",
      open:false,
      setOpen:false,
      notification:{
        typeNotification:'',
        openNotification: false,
        messageNotification: '',
      }
    };
  }

  handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({notification:{
      openNotification:false}
    })
  }

  handleChange = (e) => {
    this.setState({
      value: e.target.value
    });
  }

  handleChangeNameCategory = name => event => {
    this.setState({[name]: event.target.value })
  }

  saveCategory = async () => {

    feathersClient.service('categories').create(this.state).then((response) =>{
      this.setState({notification:{openNotification:true, messageNotification: 'Categoria agregada', typeNotification:"succes"}})
      setTimeout(
        function() {
            this.setState({notification:{openNotification:false}});
            this.props.history.push(`/categories/${response._id}`);
        }
        .bind(this),
        2000
      );
    }).catch((error)=>{
      this.setState({notification:{typeNotification:'FeathersError', messageNotification: error.message, openNotification:true}})
    });

    
  }

  dellTag = (i) =>{
    let state = JSON.parse(JSON.stringify(this.state));

    state.tags.splice(i,1);

    this.setState({tags: state.tags});

  }
    render(){
      const { tags, value, name } = this.state;
        return (
          <div>
            <Grid container spacing={16}>
              <Grid item md={7} xs={12}>
                  <Panel>
                    <div>
                      <h2>Crear Categoria</h2>
                    </div>
                    <div className="form">
                      <div>
                        <Input
                            type="text"
                            placeholder="Nombre de la categoria"
                            value={name}
                            onChange={this.handleChangeNameCategory('name')}
                            className="name-input"
                            required
                          />
                      </div>
                      <div>
                        <Button
                              onClick={this.saveCategory}
                              variant="outlined"
                              color="inherit"
                          >
                          Guardar
                        </Button>
                      </div>
                    </div>
                    </Panel>
              </Grid>
            </Grid>
            <div>
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={this.state.notification.openNotification}
              >
                <SnackbarContent
                  aria-describedby="client-snackbar"
                  style={this.state.notification.typeNotification == "FeathersError" ? {backgroundColor:'red'}: {backgroundColor: 'black'}}
                  message={
                    <span id="client-snackbar" >
                      {this.state.notification.messageNotification}
                    </span>
                  }
                  action={[
                    <IconButton 
                      key="close" 
                      aria-label="Close" 
                      color="inherit" 
                      onClick={this.handleCloseNotification}>
                      <CloseIcon />  
                    </IconButton>,
                  ]}
                />
              </Snackbar>
            </div>
          </div>
        )
    }
};