import feathers from "@feathersjs/client";
import feathersAuthClient from '@feathersjs/authentication-client';

export const host = 'https://proveedores-backend.puertadeoro.org';
// export const host = 'http://localhost:3030';

const authClient = feathersAuthClient({
  header: 'Authorization', 
  path: '/authentication', 
  jwtStrategy: 'jwt', 
  entity: 'user', 
  service: 'users', 
  storage: window.localStorage 
});

export default feathers()
  .configure(feathers.rest(host).fetch(window.fetch.bind(window)))
  .configure(authClient);